/*
 * @Author: wxb
 * @Date: 2021-06-24 11:56:09
 * @LastEditTime: 2021-08-10 23:51:02
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\permission.js
 */
import router from './router'
import store from './store'
import NProgress from 'nprogress' // 顶部进度条 progress bar
import 'nprogress/nprogress.css'// 顶部进度条央视 progress bar
import { setUserInfo, getToken } from '@/utils/auth' // getToken from cookie

NProgress.configure({ showSpinner: false })// NProgress Configuration

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  if (getToken()) {
    if (store.getters.userInfo === undefined) {
      store.dispatch('getUserInfo').then(info => { // 拉取user_info
        setUserInfo(info)
        const elements = {}
        for (let i = 0; i < info.elements.length; i++) {
          elements[info.elements[i].code] = true
        }
        localStorage.setItem('elements', JSON.stringify(elements))
      }).catch(() => {
        store.dispatch('logOut').then(() => {
          // next({ path: '/login' })
          next()
        })
      })
    }
    if (store.getters.memberType === 'tourist') {
      store.dispatch('getMemberInfo')
    }
  }
  next()
  NProgress.done() // end progress bar
})
