/*
 * @Author: wxb
 * @Date: 2021-07-06 11:01:48
 * @LastEditTime: 2021-07-08 11:11:58
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\api\user\index.js
 */
import request from '@/utils/request'

/**
 * @description: 获取当前用户信息
 * @param {type}
 * @return:
 */
export function currentUserInfo() {
  return request({
    url: '/api/admin/user/getUserDetailById',
    method: 'get'
  })
}

/**
 * @description: 获取头像
 * @param {type}
 * @return:
 */
export function getAvatar(param) {
  return request({
    url: '/api/admin/user/getAvatar',
    params: param,
    responseType: 'blob', // 服务器返回的数据类型
    method: 'get'
  })
}
/**
 * @description: 修改个人信息
 * @param {type}
 * @return:
 */
export function saveOrUpdate(obj) {
  return request({
    url: '/api/admin/user/saveOrUpdate',
    method: 'post',
    data: obj
  })
}

/**
 * @description: 获取会员开通记录
 * @param {*} params
 * @return {*}
 */
export function getMembershipRecords(params) {
  return request({
    url: '/api/admin/packageOrder/getMembershipRecords',
    method: 'get',
    params
  })
}
