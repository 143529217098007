/*
 * @Author: wxb
 * @Date: 2021-06-24 11:42:21
 * @LastEditTime: 2021-08-03 16:55:08
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\store\getters.js
 */
const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  userName: state => state.user.userInfo.name,
  elements: state => state.user.elements,
  memberType: state => state.user.memberInfo.memberType,
  headImgUrl: state => state.user.wechatInfo.headImgUrl,
  province: state => state.dictionary.province,
  noticeType: state => state.dictionary.noticeType,
  purchaseMode: state => state.dictionary.purchaseMode,
  projectType: state => state.dictionary.projectType,
  projectStage: state => state.dictionary.projectStage
}
export default getters
