/*
 * @Author: wxb
 * @Date: 2021-06-24 11:44:03
 * @LastEditTime: 2021-07-20 15:54:36
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\utils\request.js
 */
import axios from 'axios'
// import { Message, Notification } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
// import store from '@/store'
// 设置默认项
axios.defaults.timeout = 80000
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
// 创建实列
const service = axios.create()

// request拦截器
service.interceptors.request.use(config => {
  if (getToken()) {
    config.headers.Authorization = getToken() // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
  } else {
    config.headers.Authorization = 'Basic dnVlOnZ1ZQ=='
  }
  return config
}, error => {
  console.log('request拦截器错误', error)
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(response => {
  const res = response.data
  return res
}, error => {
  const response = error.response
  if (response === undefined) {
    // Message({
    //   message: '服务请求超时！',
    //   showClose: true,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    console.info('服务请求超时！')
    return Promise.reject(error)
  }
  if (response.status === 400) {
    // Message({
    //   message: '账户或密码错误！',
    //   showClose: true,
    //   type: 'warning'
    // })
    console.info('账户或密码错误！')
    return Promise.reject(error)
  }
  const info = response.data
  if (response.status === 401 && info.status === 40101) {
    // Message({
    //   message: '您已被登出',
    //   showClose: true,
    //   type: 'warning'
    // })
    removeToken()
    location.reload()
    console.info('您已被登出！')
  }
  if (response.status === 401 && info.status === 40001) {
    // Notification.warning({
    //   title: '禁止',
    //   message: info.message,
    //   type: 'error',
    //   duration: 2 * 1000
    // })
    console.info(info.message)
    return Promise.reject(error)
  }
  if (response.status === 401 && info.error_description === null) {
    // Message({
    //   message: '账户或密码错误！',
    //   showClose: true,
    //   type: 'warning'
    // })
    console.info('账户或密码错误！')
    return Promise.reject(error)
  }
  if (response.status === 401 && info.error === 'unauthorized') {
    // Message({
    //   message: '账户或密码错误！',
    //   showClose: true,
    //   type: 'warning'
    // })
    console.info('账户或密码错误！')
    return Promise.reject(error)
  }
  if (response.status === 403) {
    // Notification.warning({
    //   title: '禁止',
    //   message: info.message,
    //   type: 'error',
    //   duration: 2 * 1000
    // })
    console.info(info.message)
    return Promise.reject(error)
  }
  if (info.status === 30101) {
    // Notification.warning({
    //   title: '失败',
    //   message: info.message,
    //   type: 'error',
    //   duration: 2 * 1000
    // })
    console.info(info.message)
    return Promise.reject(error)
  }
  if (response.status === 504) {
    // Message({
    //   message: '后端服务异常，请联系管理员！',
    //   showClose: true,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    console.info('后端服务异常，请联系管理员！')
    return Promise.reject(error)
  }
  // Message({
  //   message: info.message,
  //   showClose: true,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  console.info(info.message)
  return Promise.reject(error)
})

export default service
