/*
 * @Author: wxb
 * @Date: 2021-06-24 16:40:37
 * @LastEditTime: 2021-06-28 11:14:33
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\api\vip\index.js
 */

import request from '@/utils/request'
import qs from 'qs'

/**
 * @description: 获取套餐列表
 * @param {*} params
 * @return {*}
 */
export function getSetmealList(params) {
  return request({
    url: '/api/admin/setMeal/getSetmealList',
    method: 'get',
    params
  })
}

/**
 * @description: 创建订单
 * @param {*} params
 * @return {*}
 */
export function addOrder(params) {
  return request({
    url: '/api/admin/packageOrder/addOrder',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 扫码支付后通过订单号校验订单是否支付成功
 * @param {*} params
 * @return {*}
 */
export function checkOrderInfo(params) {
  return request({
    url: '/api/admin/packageOrder/checkOrderInfo',
    method: 'get',
    params
  })
}

/**
 * @description: 获取当前登录人会员类型
 * @param {*} params
 * @return {*}
 */
export function getMaberUsersInfo() {
  return request({
    url: '/api/admin/maberUsers/getMaberUsersInfo',
    method: 'get'
  })
}

/**
 * @description: 获取当前登录人的微信用户信息
 * @param {*} params
 * @return {*}
 */
export function getWechatUserInfo(params) {
  return request({
    url: '/api/admin/maberUsers/getWechatUserInfo',
    method: 'get'
  })
}
