/*
 * @Author: wxb
 * @Date: 2021-06-29 13:58:17
 * @LastEditTime: 2021-10-26 14:07:14
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\api\dictionary\index.js
 */

import request from '@/utils/request'

/**
 * @description: 获取省份字典
 * @param {*} params
 * @return {*}
 */
export function getProvince(params) {
  return request({
    url: '/api/company/dictionaries/getProvinceList',
    method: 'get',
    params
  })
}

/**
 * @description: 获取树形字典
 * @param {*} params
 * @return {*}
 */
export function getTreeDictionary(params) {
  return request({
    url: '/api/company/dictionaries/getTreeList',
    method: 'get',
    params
  })
}

/**
 * @description: 获取列表字典
 * @param {*} params
 * @return {*}
 */
export function getListDictionary(params) {
  return request({
    url: '/api/company/dictionaries/getDictionariesList',
    method: 'get',
    params
  })
}

/**
 * @description: 获取邱平台的字典信息，列表形式
 * @param {*} code 字典code
 * @return {*}
 */
export function getTypeCodeList(code) {
  return request({
    url: `/api/dict/dictType/dictValueWithList/${code}`,
    method: 'get'
  })
}
