/*
 * @Author: wxb
 * @Date: 2021-06-15 22:49:38
 * @LastEditTime: 2021-06-29 17:31:52
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import dictionary from './modules/dictionary'
import getters from './getters'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    dictionary
  },
  getters
})
