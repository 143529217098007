/*
 * @Author: wxb
 * @Date: 2021-06-29 14:09:20
 * @LastEditTime: 2021-08-13 11:17:19
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\store\modules\dictionary.js
 */
import { getProvince, getListDictionary } from '@/api/dictionary'
const dictionary = {
  state: {
    province: [],
    noticeType: [],
    purchaseMode: [],
    projectType: [],
    projectStage: []
  },
  mutations: {
    setProvince: (state, province) => {
      state.province = province
    },
    setNoticeType: (state, noticeType) => {
      state.noticeType = noticeType
    },
    setPurchaseMode: (state, purchaseMode) => {
      state.purchaseMode = purchaseMode
    },
    setProjectType: (state, projectType) => {
      state.projectType = projectType
    },
    setProjectStage: (state, projectStage) => {
      state.projectStage = projectStage
    }
  },
  actions: {
    getProvince({ commit }) {
      return new Promise((resolve, reject) => {
        getProvince({}).then(res => {
          if (res.ok) {
            const list = res.lst
            list.unshift({ DICTIONARYITEM_ITEMNAME: '全国', DICTIONARYITEM_ITEMCODE: 'all' })
            commit('setProvince', list)
            resolve(res)
          }
        }).catch(err => {
          console.log(err)
        })
      })
    },
    getNoticeType({ commit }) {
      return new Promise((resolve, reject) => {
        getListDictionary({ code: 'NOTICETYPE' }).then(res => {
          if (res.ok) {
            const list = res.lst
            list.unshift({ DICTIONARYITEM_ITEMNAME: '全部', DICTIONARYITEM_ITEMCODE: 'all' })
            commit('setNoticeType', list)
            resolve(res)
          }
        }).catch(err => {
          console.log(err)
        })
      })
    },
    getPurchaseMode({ commit }) {
      return new Promise((resolve, reject) => {
        getListDictionary({ code: 'PURCHASEWAY' }).then(res => {
          if (res.ok) {
            const list = res.lst
            list.unshift({ DICTIONARYITEM_ITEMNAME: '全部', DICTIONARYITEM_ITEMCODE: 'all' })
            commit('setPurchaseMode', list)
            resolve(res)
          }
        }).catch(err => {
          console.log(err)
        })
      })
    },
    getProjectType({ commit }) {
      return new Promise((resolve, reject) => {
        getListDictionary({ code: 'XMLX' }).then(res => {
          if (res.ok) {
            const list = res.lst
            list.unshift({ DICTIONARYITEM_ITEMNAME: '全部', DICTIONARYITEM_ITEMCODE: 'all' })
            commit('setProjectType', list)
            resolve(res)
          }
        }).catch(err => {
          console.log(err)
        })
      })
    },
    getProjectStage({ commit }) {
      return new Promise((resolve, reject) => {
        getListDictionary({ code: 'PROSTAGE' }).then(res => {
          if (res.ok) {
            const list = res.lst
            list.unshift({ DICTIONARYITEM_ITEMNAME: '全部', DICTIONARYITEM_ITEMCODE: 'all' })
            commit('setProjectStage', list)
            resolve(res)
          }
        }).catch(err => {
          console.log(err)
        })
      })
    }
  }
}

export default dictionary
