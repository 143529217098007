/*
 * @Author: wxb
 * @Date: 2021-06-24 11:43:43
 * @LastEditTime: 2021-06-24 14:32:55
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\api\login\index.js
 */
import request from '@/utils/request'
// 登录
export function loginByUsername(username, password) {
  const data = {
    username,
    password,
    grant_type: 'password'
  }
  return request({
    url: '/api/auth/oauth/token',
    method: 'post',
    data,
    transformRequest: [(data, headers) => {
      // Do whatever you want to transform the data
      let ret = ''
      for (const it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret
    }],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取个人信息
export function getInfo() {
  return request({
    url: '/api/admin/user/front/info',
    method: 'get'
  })
}

// layout登出
export function logout(token) {
  return request({
    url: '/api/auth/oauth/token',
    method: 'delete',
    params: {
      access_token: token
    }
  })
}
/**
 * @description: 更换密码
 * @param {type}
 * @return:
 */
export function changePassword(data) {
  return request({
    url: '/api/admin/user/changePassword',
    method: 'post',
    params: data
  })
}

/**
 * @description: 获取登录二维码
 * @param {*}
 * @return {*}
 */
export function getQrCode() {
  return request({
    url: '/api/admin/wechatLogin/getQrCode',
    method: 'get'
  })
}

/**
 * @description: 检测登录
 * @param {*} params
 * @return {*}
 */
export function checkLogin(params) {
  return request({
    url: '/api/admin/wechatLogin/checkLogin',
    method: 'get',
    params
  })
}
