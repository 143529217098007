<!--
 * @Author: wxb
 * @Date: 2021-06-16 17:07:49
 * @LastEditTime: 2023-06-16 17:40:56
 * @LastEditors: wangxb 18254141586@163.com
 * @Description:
 * @FilePath: \ui-djz\src\components\footerNav.vue
-->
<template>
  <footer class="footer-container">
    <el-backtop>
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <div class="container">
      <div class="module-list">
        <div class="module-item">
          <div class="module-item-title">新闻资讯</div>
          <div class="module-item-content">
            <ul class="link-list">
              <li>行业资讯</li>
              <li>公司新闻</li>
            </ul>
          </div>
        </div>
        <div class="module-item">
          <div class="module-item-title">相关产品</div>
          <div class="module-item-content">
            <ul class="link-list">
              <li @click="handleClickLink('http://zhhgyq.djz365.com/zhhgyq/nav')">智慧化工</li>
              <li @click="handleClickLink('http://demo.djz365.com')">交付管家</li>
            </ul>
          </div>
        </div>
        <!-- <div class="module-item">
          <div class="module-item-title">产品中心</div>
          <div class="module-item-content">
            <ul class="link-list">
              <li>产品中心</li>
              <li>产品比对</li>
            </ul>
          </div>
        </div> -->
        <div class="module-item">
          <div class="module-item-title">关于我们</div>
          <div class="module-item-content">
            <ul class="link-list">
              <li>公司简介</li>
              <li>企业文化</li>
              <li>公司架构</li>
              <li>团队展示</li>
            </ul>
          </div>
        </div>
        <div class="module-item">
          <div class="module-item-title">联系我们</div>
          <div class="module-item-content">
            <ul class="link-list">
              <li>联系我们</li>
              <li>公司招聘</li>
            </ul>
          </div>
        </div>
        <div class="module-item">
          <img class="qr-code"
               src="../assets/qrcode.png">
          <div class="share">
            <img src="../assets/qq.png">
            <img src="../assets/wechat.png">
          </div>
        </div>
      </div>
      <div class="copyright">
        <div>版权所有© 度金针（北京）信息科技有限公司</div>
        <div>备案号：<a class="record"
             href="https://beian.miit.gov.cn/"
             target="_blank">京ICP备17047831号-2</a></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'footerNav',
  methods: {
    handleClickLink(href) {
      window.open(href)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-container {
  background-color: rgba(6, 6, 6, 1);
  padding-bottom: 3rem;
  width: 100%;
  min-width: 144rem;
  .container {
    width: 144rem;
    margin: 0 auto;
    padding-top: 4.5rem;
    .module {
      &-list {
        width: 144rem;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-bottom: 5rem;
      }
      &-item {
        width: 24rem;
        text-align: center;
        &-title {
          font-size: 2.4rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #fafafa;
        }
        &-content {
          margin-top: 20px;
          .link-list li {
            font-size: 1.8rem;
            font-weight: normal;
            letter-spacing: 0px;
            color: #9fa0a1;
            margin-bottom: 1rem;
            cursor: pointer;
          }
        }
        .qr-code {
          width: 14rem;
          height: 14rem;
        }

        .share {
          margin-top: 3rem;
          img {
            margin-left: 1rem;
          }
        }
      }
    }

    .copyright {
      // margin-top: 1rem;
      // border-top: 0.1rem solid #5b5b5b;
      text-align: center;
      // padding-top: 3.8rem;
      div {
        font-size: 1.4rem;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.8rem;
        letter-spacing: 0;
        color: #898a8c;

        .record {
          color: #898a8c;
        }
      }
    }
  }
}
</style>
