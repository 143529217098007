/*
 * @Author: wxb
 * @Date: 2021-06-24 11:59:37
 * @LastEditTime: 2021-07-20 10:27:33
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\filters\index.js
 */

/**
 * @name: 千分位格式化金额
 * @param {Number} money 需要格式化的金额
 * @return: 格式化之后的金额
 */
export function formatMoney(money) {
  let ftmoney = money
  if (isNaN(parseFloat(ftmoney))) {
    ftmoney = 0.00
  }
  const num = parseFloat(ftmoney).toFixed(2)
  return num.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
}

/**
 * @name: 时间格式化
 * @param {type} datetime 需要格式化的时间
 * @return: 格式化之后的时间
 */
export function formatterDate(datetime) {
  let date = ''
  if (datetime) {
    date = new Date(datetime)
    const year = date.getFullYear()
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    const month =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    // 拼接
    return year + '-' + month + '-' + day + ' '
  } else {
    return ''
  }
}
