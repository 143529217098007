/*
 * @Author: wxb
 * @Date: 2021-06-24 11:42:41
 * @LastEditTime: 2021-06-28 16:16:54
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\store\modules\user.js
 */
import { getInfo, logout } from '@/api/login'
import { getUserInfo, getToken, removeToken, removeUserInfo } from '@/utils/auth'
import { getWechatUserInfo, getMaberUsersInfo } from '@/api/vip'
const user = {
  state: {
    userInfo: getUserInfo(),
    token: getToken(),
    elements: JSON.parse(localStorage.getItem('elements')),
    memberInfo: { memberType: 'tourist' },
    wechatInfo: {}
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_ELEMENTS: (state, elements) => {
      state.elements = elements
    },
    SET_MEMBERINFO: (state, memberInfo) => {
      state.memberInfo = memberInfo
    },
    SET_WECHATINFO: (state, wechatInfo) => {
      state.wechatInfo = wechatInfo
    }
  },
  actions: {
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          commit('SET_USERINFO', response)
          const elements = {}
          for (let i = 0; i < response.elements.length; i++) {
            elements[response.elements[i].code] = true
          }
          commit('SET_ELEMENTS', elements)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    logOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        const token = state.token
        commit('SET_TOKEN', '')
        commit('SET_MEMBERINFO', { memberType: 'tourist' })
        removeToken()
        removeUserInfo()
        logout(token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_MEMBERINFO', { memberType: 'tourist' })
          commit('SET_USERINFO', undefined)
          removeToken()
          removeUserInfo()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    setMEMBERINFO({ commit }, memberInfo) {
      commit('SET_MEMBERINFO', memberInfo)
    },
    getMemberInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getMaberUsersInfo().then(response => {
          const memberUserInfo = response.memberUserInfo || {}
          const memberInfo = {
            startTime: memberUserInfo.startTime,
            endTime: memberUserInfo.endTime,
            memberType: memberUserInfo.memberType === '1' ? 'vipUser' : 'ordinaryUser'
          }
          commit('SET_MEMBERINFO', memberInfo)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getWechatInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getWechatUserInfo().then(response => {
          commit('SET_WECHATINFO', response.wechatUserInfo || {})
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
