<!--
 * @Author: wxb
 * @Date: 2021-06-16 14:10:17
 * @LastEditTime: 2021-07-22 14:10:30
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Home.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='1'></headerNav>
    <div class="banner">
      <el-image :src="bannerImg"
                fit="cover "></el-image>
    </div>
    <!-- 项目模块 -->
    <div class="project-panel">
      <div class="project-panel-info">
        <div class="project-panel-title">项目资讯不止眼前的公告标讯，还有诗和远方- - </div>
        <div class="project-panel-desc">
          <p>度金针的项目概念，不是简单的公告标讯，</p>
          <p> 而是从拟建项目开始，包含项目各个标段、过程的整套系统性项目档案。</p>
        </div>
        <div class="project-panel-list">
          <div class="project-panel-list-item">
            <div class="project-panel-list-item-divide"></div>
            <div class="project-panel-list-item-icon">
              <img src="../assets/Home/project-panel-1.png" />
            </div>
            <div class="project-panel-list-item-title">拟建项目</div>
          </div>
          <div class="project-panel-list-item">
            <div class="project-panel-list-item-divide"></div>
            <div class="project-panel-list-item-icon">
              <img src="../assets/Home/project-panel-2.png" />
            </div>
            <div class="project-panel-list-item-title">项目历程</div>
          </div>
          <div class="project-panel-list-item">
            <div class="project-panel-list-item-divide"></div>
            <div class="project-panel-list-item-icon">
              <img src="../assets/Home/project-panel-3.png" />
            </div>
            <div class="project-panel-list-item-title">项目进度</div>
          </div>
          <div class="project-panel-list-item">
            <div class="project-panel-list-item-divide"></div>
            <div class="project-panel-list-item-icon">
              <img src="../assets/Home/project-panel-4.png" />
            </div>
            <div class="project-panel-list-item-title">相关单位</div>
          </div>
          <div class="project-panel-list-item">
            <div class="project-panel-list-item-icon">
              <img src="../assets/Home/project-panel-5.png" />
            </div>
            <div class="project-panel-list-item-title">分析报告</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 机构模块 -->
    <div class="organization-panel">
      <div class="organization-panel-info">
        <div class="organization-panel-title">知己知彼，百战不殆，如何知彼，金针绣来 </div>
        <div class="organization-panel-list">
          <div class="organization-panel-list-item">
            <div class="organization-panel-list-item-No">1.</div>
            <div class="organization-panel-list-item-desc">
              智能化建设行业<span class="number">10万+</span>建设单位、招标代理、投标方、承建单位、供应商信息库。
            </div>
          </div>
          <div class="organization-panel-list-item">
            <div class="organization-panel-list-item-No">2.</div>
            <div class="organization-panel-list-item-desc">机构及企业基本信息、资质、获奖荣誉、参展系信息，参见项目<span class="number">全方位</span>涵盖。</div>
          </div>
          <div class="organization-panel-list-item">
            <div class="organization-panel-list-item-No">3.</div>
            <div class="organization-panel-list-item-desc">独家提供企业<span class="number">人员资质</span>查询，精准锁定目标专家。</div>
          </div>
          <div class="organization-panel-list-item">
            <div class="organization-panel-list-item-No">4.</div>
            <div class="organization-panel-list-item-desc">企企之间横向比对，一键弹出<span class="number">关系链</span>。</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜文库 -->
    <div class="library-panel">
      <div class="library-panel-info">
        <div class="library-panel-title">纸上得来虽觉浅，千法万案总提思 </div>
        <div class="library-panel-desc">
          <p>度金针海量文库，全部聚集智能化建设行业，项目实施各个阶段；行业发展各种规范；设计售前环节方案；会员终生免费下载。</p>
        </div>
        <div class="library-panel-list">
          <div class="library-panel-list-item">
            <div class="library-panel-list-item-icon">
              <img src="../assets/Home/library-1.png" />
              <div class="library-panel-list-item-title">拟建项目</div>
            </div>
          </div>
          <div class="library-panel-list-item">
            <div class="library-panel-list-item-icon">
              <img src="../assets/Home/library-2.png" />
              <div class="library-panel-list-item-title">项目历程</div>
            </div>
          </div>
          <div class="library-panel-list-item">
            <div class="library-panel-list-item-icon">
              <img src="../assets/Home/library-3.png" />
              <div class="library-panel-list-item-title">项目进度</div>
            </div>
          </div>
          <div class="library-panel-list-item">
            <div class="library-panel-list-item-icon">
              <img src="../assets/Home/library-4.png" />
              <div class="library-panel-list-item-title">相关单位</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 知识图谱 -->
    <div class="knowledgegraph-panel">
      <div class="knowledgegraph-panel-info">
        <div class="knowledgegraph-panel-title">把你的企业他的项目串一串，串一个幸运草串一个关系链 </div>
        <div class="knowledgegraph-panel-desc">
          <p>度金针独家设计，通过强大的图谱功能，将机构，项目，标讯，产品，资质，展会，荣誉，标准规范，</p>
          <p> 方案，专家等元素全部关联起来，形成超级实用的智建指示图谱。</p>
          <p>不知道想查什么的时候，就来图谱点一点！</p>
        </div>
      </div>
    </div>
    <footerNav></footerNav>
    <el-dialog :visible.sync="centerDialogVisible"
               width="567px"
               top="25vh"
               :lock-scroll="false"
               :close-on-click-modal="false"
               :show-close="false"
               center>
      <div class="activity"
           @click="handleClickActivity">
        <img src="../assets/dzjvip.png">
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button icon="el-icon-close"
                   class="close-btn"
                   circle
                   size="mini"
                   @click="centerDialogVisible = false"></el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import headerNav from '@/components/headerNav'
import footerNav from '@/components/footerNav'
import { getToken } from '@/utils/auth'
export default {
  name: 'home',
  components: {
    headerNav,
    footerNav
  },
  data() {
    return {
      bannerImg: require('../assets/Home/banner.png'),
      centerDialogVisible: false
    }
  },
  mounted() {
    if (!getToken()) {
      const firstUse = sessionStorage.getItem('firstUse')
      if (!firstUse) {
        this.centerDialogVisible = true
        sessionStorage.setItem('firstUse', 'firstUse')
      } else {
        this.centerDialogVisible = false
      }
    } else {
      sessionStorage.setItem('firstUse', 'firstUse')
    }
  },
  methods: {
    handleClickActivity() {
      localStorage.setItem('sourceurl', this.$route.fullPath)
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 弹窗
/deep/ .el-dialog {
  background: none;
  box-shadow: none;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 0 0 29px;
}

.activity {
  cursor: pointer;
}

.close-btn {
  background: #bdbdbd;
  border: 1px solid #bdbdbd;
}

.banner {
  width: 100%;
  min-width: 144rem;
  height: auto;
  .el-image {
    width: 100%;
  }
}
// 项目模块
.project-panel {
  width: 100%;
  min-width: 144rem;
  height: 75rem;
  background: url("../assets/Home/bg-1.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &-info {
    width: 120rem;
    margin: 0 auto;
  }

  &-title {
    text-align: center;
    font-size: 3.6rem;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    padding-top: 15rem;
  }

  &-desc {
    padding-top: 3.5rem;
    font-size: 1.8rem;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    font-weight: 400;
    p {
      color: rgba(0, 0, 0, 0.65);
      line-height: 3rem;
    }
  }

  &-list {
    position: relative;
    top: 10.5rem;
    display: flex;
    justify-content: space-around;
    &-item {
      flex: 1;
      text-align: center;
      position: relative;
      &-icon {
        img {
          width: 18.6rem;
          height: 18.6rem;
        }
      }

      &-title {
        margin-top: 1rem;
        font-size: 1.8rem;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
      }

      &-divide {
        position: absolute;
        right: 0;
        top: 5rem;
        width: 0.1rem;
        height: 10.4rem;
        background: #d8d8d8;
      }
    }
  }
}

// 机构模块
.organization-panel {
  width: 100%;
  min-width: 144rem;
  height: 75rem;
  background: url("../assets/Home/bg-2.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &-info {
    width: 120rem;
    margin: 0 auto;
  }

  &-title {
    font-size: 3.6rem;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    text-align: center;
    padding-top: 15rem;
  }

  &-list {
    padding-top: 10.5rem;

    &-item {
      height: 9.7rem;
      display: flex;
      align-items: center;
      border-bottom: 0.1rem dotted #4384a3;
      &:nth-child(odd) {
        background: rgba(28, 71, 94, 0.5);
      }

      &-No {
        width: 6.4rem;
        height: 5.2rem;
        background: url("../assets/Home/number.png");
        text-align: center;
        font-size: 2.4rem;
        color: #ffffff;
        font-weight: 400;
        line-height: 5.2rem;
      }

      &-desc {
        flex: 1;
        padding-left: 2rem;
        font-size: 1.8rem;
        color: rgba(255, 255, 255, 0.65);
        line-height: 3rem;
        font-weight: 400;

        .number {
          font-size: 3.6rem;
          color: #ffb248;
          line-height: 3rem;
          font-weight: 400;
        }
      }
    }
  }
}

// 搜文库模块
.library-panel {
  width: 100%;
  min-width: 144rem;
  height: 75rem;
  background: url("../assets/Home/bg-1.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &-info {
    width: 120rem;
    margin: 0 auto;
  }

  &-title {
    text-align: center;
    font-size: 3.6rem;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    padding-top: 15rem;
  }

  &-desc {
    padding-top: 3.5rem;
    font-size: 1.8rem;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    font-weight: 400;
    p {
      color: rgba(0, 0, 0, 0.65);
      line-height: 3rem;
    }
  }

  &-list {
    position: relative;
    top: 5rem;
    display: flex;
    justify-content: space-around;
    &-item {
      flex: 1;
      text-align: center;
      position: relative;
      &-icon {
        position: relative;
        img {
          width: 25.8rem;
          height: auto;
        }
      }

      &-title {
        position: absolute;
        top: 12rem;
        left: 6.9rem;
        font-size: 2rem;
        color: rgba(255, 255, 255, 0.85);
        font-weight: 500;
        width: 16rem;
        height: 5.4rem;
        background: rgba(0, 0, 0, 0.5);
        line-height: 5.4rem;
      }
    }
  }
}

// 知识图谱
.knowledgegraph-panel {
  width: 100%;
  min-width: 144rem;
  height: 75rem;
  background: url("../assets/Home/bg-3.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &-info {
    width: 120rem;
    margin: 0 auto;
  }

  &-title {
    text-align: center;
    font-size: 3.6rem;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    padding-top: 12rem;
  }

  &-desc {
    padding-top: 3.5rem;
    font-size: 1.8rem;
    color: rgba(255, 255, 255, 0.65);
    text-align: center;
    font-weight: 400;
    p {
      color: rgba(255, 255, 255, 0.65);
      line-height: 3rem;
    }
  }
}

@media screen and (max-width: 1500px) {
  .knowledgegraph-panel {
    background-position: -20rem 0;
  }
}
</style>
