/*
 * @Author: wxb
 * @Date: 2021-06-15 22:49:38
 * @LastEditTime: 2021-07-27 17:23:21
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './filters'
import ElementUI from 'element-ui'
// 引入全局公用样式
import '@/style/common.scss'
import 'element-ui/lib/theme-chalk/index.css'
import './utils/dialog'
import kity from 'kity'
import kityminder from 'kityminder-core'
// 引入登录权限配置
import './permission'

// 引入video插件
import Video from 'video.js'
import 'video.js/dist/video-js.css'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.use(ElementUI)
Vue.use(kity)
Vue.use(kityminder)
Vue.config.productionTip = false
Vue.prototype.$video = Video

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
