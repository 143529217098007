/*
 * @Author: wxb
 * @Date: 2021-06-24 11:43:02
 * @LastEditTime: 2021-08-02 14:59:55
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\utils\auth.js
 */
import Cookies from 'js-cookie'

const TokenKey = 'Authorization'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

const UserKey = 'userInfo'

export function setUserInfo(userInfo) {
  return Cookies.set(UserKey, JSON.stringify(userInfo))
}

export function getUserInfo() {
  if (Cookies.get(UserKey)) {
    return JSON.parse(Cookies.get('userInfo'))
  } else {
    return undefined
  }
}

export function removeUserInfo() {
  return Cookies.remove(UserKey)
}
