/*
 * @Author: wxb
 * @Date: 2021-06-15 22:49:38
 * @LastEditTime: 2023-05-25 10:24:23
 * @LastEditors: wangxb 18254141586@163.com
 * @Description:
 * @FilePath: \ui-djz\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('../views/Vip/index.vue'),
    meta: {
      title: 'VIP'
    }
  },
  {
    path: '/organization',
    name: 'organization',
    component: () => import('../views/Organization/index.vue'),
    meta: {
      title: '查机构'
    },
    redirect: '/orgSearch',
    children: [{
      path: '/orgSearch',
      component: () => import('../views/Organization/search.vue'),
      name: 'orgSearch',
      meta: {
        title: '机构信息'
      }
    }, {
      path: '/compareOrg',
      component: () => import('../views/Organization/compareOrg.vue'),
      name: 'compareOrg',
      meta: {
        title: '机构对比'
      }
    }, {
      path: '/compareCert',
      component: () => import('../views/Organization/compareCert.vue'),
      name: 'compareCert',
      meta: {
        title: '资质对比'
      }
    }]
  },
  {
    path: '/orgDetail/:id',
    name: 'orgDetail',
    component: () => import('../views/Organization/orgDetail.vue'),
    meta: {
      title: '机构详情'
    }
  },
  {
    path: '/seniorSearch',
    name: 'seniorSearch',
    component: () => import('../views/Organization/seniorSearch.vue'),
    meta: {
      title: '机构高级搜索'
    }
  },
  {
    path: '/achievement',
    name: 'achievement',
    component: () => import('../views/Achievement/index.vue'),
    meta: {
      title: '项目业绩'
    }
  },
  {
    path: '/registrar',
    name: 'registrar',
    component: () => import('../views/Registrar/index.vue'),
    meta: {
      title: '从业人员'
    }
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/Project/index.vue'),
    meta: {
      title: '找项目'
    },
    redirect: '/finishNotice',
    children: [{
      path: '/proposedPro',
      component: () => import('../views/Project/proposedPro.vue'),
      name: 'proposedPro',
      meta: {
        title: '拟建项目'
      }
    }, {
      path: '/builtPro',
      component: () => import('../views/Project/builtPro.vue'),
      name: 'builtPro',
      meta: {
        title: '已建项目'
      }
    }, {
      path: '/finishNotice',
      component: () => import('../views/Project/finishNotice.vue'),
      name: 'finishNotice',
      meta: {
        title: '精编公告'
      }
    }, {
      path: '/originalNotice',
      component: () => import('../views/Project/originalNotice.vue'),
      name: 'originalNotice',
      meta: {
        title: '原始公告'
      }
    }]
  },
  {
    path: '/proposedProInfo/:id',
    name: 'proposedProInfo',
    component: () => import('../views/Project/proposedProInfo.vue'),
    meta: {
      title: '拟建项目详情'
    }
  },
  {
    path: '/builtProInfo/:id',
    name: 'builtProInfo',
    component: () => import('../views/Project/builtProInfo.vue'),
    meta: {
      title: '已建项目详情'
    }
  },
  {
    path: '/noticeDetail/:id',
    name: 'noticeDetail',
    component: () => import('../views/Project/noticeDetail.vue'),
    meta: {
      title: '公告详情'
    }
  },
  {
    path: '/libary',
    name: 'libary',
    component: () => import('../views/Document/index.vue'),
    meta: {
      title: '搜文库'
    }
  }, {
    path: '/libaryDetail/:id/:type',
    name: 'libaryDetail',
    component: () => import('../views/Document/libaryDetail.vue'),
    meta: {
      title: '文库详情'
    }
  }, {
    path: '/preview/:filekey',
    name: 'preview',
    component: () => import('../views/Document/preview.vue'),
    meta: {
      title: '预览'
    }
  }, {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/Personal/index.vue'),
    meta: {
      title: '用户中心'
    },
    redirect: '/userInfo',
    children: [{
      path: '/userInfo',
      component: () => import('../views/Personal/userInfo.vue'),
      name: 'userInfo',
      meta: {
        title: '个人信息'
      }
    }, {
      path: '/myFollow',
      component: () => import('../views/Personal/myFollow.vue'),
      name: 'myFollow',
      meta: {
        title: '我的关注'
      }
    }, {
      path: '/myCollect',
      component: () => import('../views/Personal/myCollect.vue'),
      name: 'myCollect',
      meta: {
        title: '我的收藏'
      }
    }]
  }, {
    path: '/special',
    name: 'special',
    component: () => import('../views/Subject/index.vue'),
    meta: {
      title: '专题'
    }
  }, {
    path: '/industry',
    name: 'industry',
    component: () => import('../views/Subject/industry.vue'),
    meta: {
      title: '行业知识体系'
    }
  }, {
    path: '/park',
    name: 'park',
    component: () => import('../views/Subject/park.vue'),
    meta: {
      title: '园区专题'
    }
  }, {
    path: '/parkDetail/:id',
    name: 'parkDetail',
    component: () => import('../views/Subject/parkDetail.vue'),
    meta: {
      title: '园区详情'
    }
  }, {
    path: '/exhibition',
    name: 'exhibition',
    component: () => import('../views/Subject/exhibition.vue'),
    meta: {
      title: '展会专题'
    },
    redirect: '/preExhi',
    children: [{
      path: '/preExhi',
      component: () => import('../views/Subject/preExhi.vue'),
      name: 'preExhi',
      meta: {
        title: '拟办展会'
      }
    }, {
      path: '/buildExhi',
      component: () => import('../views/Subject/buildExhi.vue'),
      name: 'buildExhi',
      meta: {
        title: '已办展会'
      }
    }]
  }, {
    path: '/preExhiDetail/:id',
    name: 'preExhiDetail',
    component: () => import('../views/Subject/preExhiDetail.vue'),
    meta: {
      title: '拟办展会详情'
    }
  }, {
    path: '/buildExhiDetail/:id',
    name: 'buildExhiDetail',
    component: () => import('../views/Subject/buildExhiDetail.vue'),
    meta: {
      title: '拟办展会详情'
    }
  }, {
    path: '/graph',
    name: 'graph',
    component: () => import('../views/Graph/index.vue'),
    meta: {
      title: '图谱关系'
    }
  }, {
    path: '/starCompany',
    name: 'starCompany',
    component: () => import('@/views/StarCompany/index.vue'),
    meta: {
      title: '明星企业'
    }
  }, {
    path: '/starCompanyDetail/:id/:nav',
    name: 'starCompanyDetail',
    component: () => import('@/views/StarCompany/detail.vue'),
    meta: {
      title: '明星企业详情'
    }
  }, {
    path: '/productDetail/:comid/:proid',
    name: 'productDetail',
    component: () => import('@/views/StarCompany/productDetail.vue'),
    meta: {
      title: '明星企业-产品详情'
    }
  }, {
    path: '/solutionDetaill/:comid/:proid',
    name: 'solutionDetaill',
    component: () => import('@/views/StarCompany/solutionDetaill.vue'),
    meta: {
      title: '明星企业-解决方案详情'
    }
  }, {
    path: '/aiAssistant',
    name: 'aiAssistant',
    component: () => import('@/views/Assistant/index.vue'),
    meta: {
      title: 'AI助理'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
